import {request} from './request';
const BASE = '/admin'
// 获取配货明细列表
export const distribution_item_list = (data) => {//distribution_item_list
    return request(`distribution/distribution_item_list`,"post",data)
}
// 扫码配货
export const scan_to_distribution = (data) => {
    return request(`distribution/scan_to_distribution`,"post",data)
}
// 扫码取消&删除配货明细
export const scan_to_cancel = (data) => {
    return request(`distribution/scan_to_cancel`,"post",data)
}
// 提交配货单
export const submit_distribution = (data) => {
    return request(`distribution/submit_distribution`,"post",data)
}
// 新增配货单
export const add_distribution = (data) => {
    return request(`distribution/get_new_distribution`,"post",data)
}
// 获取配货单列表
export const distribution_list = (data) => {
    return request(`distribution/distribution_list`,"post",data)
}
// 一键取消配货单
export const oneclick_cancel_distribution = (data) => {
    return request(`distribution/oneclick_cancel_distribution`,"post",data)
}