import { createRouter, createWebHashHistory,createWebHistory } from 'vue-router'
import DistributionList from '@/views/DistributionList.vue'
import Login from '@/views/Login.vue'
import Index from '@/views/Index.vue'
import OrderList from "@/views/OrderList.vue"
import Distribution from "@/views/Distribution.vue"
import SaveList from "@/views/SaveList.vue"
import Retention from "@/views/Retention.vue"
import { ElMessage } from "element-plus";

const routes = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/distributionList/:distribution_id',
    name: 'distributionList',
    component: DistributionList
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/index',
    name: 'index',
    component: Index
  },
  {
    path: '/orderList',
    name: 'orderList',
    component: OrderList
  },
  {
    path: '/distribution/:order_id',
    name: 'distribution',
    component: Distribution
  },
  {
    path: '/saveList',
    name: 'saveList',
    component: SaveList
  },
  {
    path: '/retention/:retention_id',
    name: 'retention',
    component: Retention
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

const isPass = (list, path, param) => {
  list.map((item) => {
    if (
      item.node_path &&
      path.includes(item.node_path) &&
      item.node_path != null
    ) {
      param.show = true;
    } else if (item.child && item.child.length > 0 && param.show == false) {
      isPass(item.child, path, param);
    }
  });
};
router.beforeEach((to, from, next) => {
  //beforeEach是router的钩子函数，在进入路由前执行
  // if(to.path != '/login' && from.path != '/login' && (token == null ||token == '' ||token == undefined)) next("/login")
  const token = sessionStorage.getItem("token");
  const userNodeList = sessionStorage.getItem("setUserNodeList");
  // let isShow = false;
  let param = {
    show: false,
  };
  if (JSON.parse(userNodeList) && JSON.parse(userNodeList).length > 0) {
    const list = JSON.parse(userNodeList);
    isPass(list, to.path, param);
  }
  if (to.path == "/login") {
    next();
  } else {
    // 没有token设置跳转登录页
    if (token == null || token == "" || token == undefined) {
      next("/login");
    } else {
      if (to.matched.length === 0) {
        ElMessage({
          message: "跳转路径配置错误",
          type: "warning",
        });
      } else if (param.show || to.path == "/" || to.path == "/index") {
        next();
      } else {
        ElMessage({
          message: "没有权限",
          type: "warning",
        });
      }
    }
  }
});

export default router
