// import {request2} from './request';
// const BASE = '/admin'
// // 订单列表
// export const order_list = (data) => {
//     return request2(`${BASE}/order/order_list`,"post",data)
// }
import {request2,request} from './request';
const BASE = '/admin'
// 订单列表
export const order_list = (data) => {
    return request(`order/counter_order_list`,"post",data)
}
// 订单详情
export const counter_item_list = (data) => {
    return request(`order/counter_item_list`,"post",data)
    // return request(`order/counter_item_list_test`,"post",data)
}
// 获取品类
export const retention_add_remarks = (data) => {
    return request(`order/counter_item_list_test`,"post",data)
}
//取消订单（柜台）
export const counter_cancel = (data) => {
    return request(`order/counter_cancel`,"post",data)
}
//取消订单（件数）
export const cancel_item_num = (data) => {
    return request(`order/cancel_item_num`,"post",data)
}


