<template>
  <div class="distribution" @click="data.item.isDelete = false">
    <div class="distribution-header">
      <div style="display: flex; align-items: center">
        <span @click="skip"
          ><el-icon><ArrowLeftBold /></el-icon
        ></span>
        <span style="color: #e6a23c">
          {{
            data.param.submit_status == 1
              ? "已提交"
              : data.value3
              ? "配货中..."
              : "取消配货中.."
          }}
        </span>
      </div>

      <div class="distribution-title" @click="changeEvent">配货列表</div>
      <!-- <div style="display: flex">
        <el-input v-model="data.barcode" />
        <el-button @click="changeEvent">配货</el-button>
      </div> -->
      <el-switch
        v-if="data.param.submit_status == 0"
        v-model="data.value3"
        inline-prompt
        active-text="配货"
        inactive-text="取消"
      />
    </div>
    <div class="distribution-information">
      <div style="display: flex; justify-content: space-between">
        <div style="width: 50%">
          <span class="distribution-information-title">订单号：</span>
          {{ data.param.yzt_order_num }}
        </div>
        <div style="width: 50%">
          <span class="distribution-information-title">配货单号：</span>
          {{ data.param.doc_num }}
        </div>
      </div>
      <div style="display: flex; justify-content: space-between">
        <div style="width: 50%">
          <span class="distribution-information-title">配货件数：</span>
          {{ data.param.quantity }}
        </div>
        <div style="width: 50%">
          <span class="distribution-information-title">订单件数：</span>
          {{ data.param.counter_quantity }}
        </div>
      </div>
      <div style="display: flex">
        <div style="width: 50%">
          <span class="distribution-information-title">配货克重：</span>
          {{ data.param.weight }}
        </div>
        <div style="width: 50%">
          <span class="distribution-information-title" style="width: 110px"
            >其他已配件数：</span
          >
          {{ data.param.counter_quantity_ed }}
        </div>
      </div>
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          margin-bottom:5px
        "
      >
        <span class="distribution-information-title">条码：</span
        ><el-input
          style="width: calc(100% - 60px)"
          v-model="data.barcode2"
          @blur="updateBarcode"
        />
      </div>
      <div style="display: flex">
        <el-button
          :type="data.title == '配货单列表' ? 'primary' : ''"
          style="width: 49%"
          @click="data.title = '配货单列表'"
          >配货明细</el-button
        >
        <el-button
          :type="data.title != '配货单列表' ? 'primary' : ''"
          style="width: 49%"
          @click="data.title = '订单详情'"
          >订单详情</el-button
        >
      </div>
    </div>
    <div v-show="data.title != '订单详情'" class="distribution-body">
      <div
        class="distribution-item"
        v-for="(item, index) in data.tableData"
        :key="index"
      >
        <div
          class="distribution-item-title"
          @click="item.show = !item.show"
          @touchstart="touchStartEvent"
          @touchend="touchEndEvent($event, item)"
        >
          <span>条码：{{ item.barcode }}</span>
          <span>克重：{{ item.weight }}g</span>
          <el-button
            type="danger"
            style="position: relative"
            v-show="item.isDelete"
            @click.stop="deleteDistribution(item.barcode)"
            >删除</el-button
          >
        </div>
        <div class="distribution-item-body" v-show="item.show">
          <!-- <img style="width: 80px; height: 80px" :src="item.img" alt="" /> -->

          <el-image
            style="width: 80px; height: 80px"
            :src="item.img"
            :zoom-rate="1.2"
            :max-scale="7"
            :min-scale="0.2"
            :preview-src-list="[item.img]"
            :initial-index="4"
            fit="cover"
          />
          <div>
            <div>款号：{{ item.goods_code }}</div>
            <div>款式名称：{{ item.goods_name }}</div>
            <div>一级编码：</div>
            <div>二级编码：</div>
          </div>
        </div>
      </div>
    </div>
    <div class="distribution-body" v-show="data.title == '订单详情'">
      <div style="display: flex; margin-bottom: 5px">
        <el-select
          placeholder="请选择品类"
          v-model="data.level_3"
          @change="getCounterItemList"
        >
          <el-option
            :label="item.cate_name"
            :value="item.cate_id"
            v-for="item in data.cate_item_attr"
          ></el-option>
        </el-select>
        <el-switch
          style="margin-left: 10px"
          v-model="data.value5"
          inline-prompt
          active-text="全部"
          inactive-text="未配货"
          @change="getCounterItemList"
        />
      </div>
      <div
        class="distribution-item"
        v-for="(item, index) in data.tableData2"
        :key="index"
      >
        <div class="distribution-item-title" @click="item.show = !item.show">
          <span
            >{{ item.goods_code }}
            <span style="padding-left: 10px">{{ item.sku }}</span></span
          >
          <span>{{ item.quantity }}件</span>
        </div>
        <div class="distribution-item-body" v-show="item.show">
          <el-image
            style="width: 80px; height: 80px"
            :src="item.thumb_img"
            :zoom-rate="1.2"
            :max-scale="7"
            :min-scale="0.2"
            :preview-src-list="[item.thumb_img]"
            :initial-index="4"
            fit="cover"
          />
          <div style="width: calc(100% - 80px)">
            <div style="display: flex; justify-content: space-between">
              <div>
                <div>工厂款号：{{ item.supplier_goods_code }}</div>
                <div>品类：{{ item.cate_name }}</div>
              </div>
              <el-button
                type="primary"
                @click="orderItemDistribution(item)"
                plain
                >配货</el-button
              >
              <el-button
                type="danger"
                @click="cancelOrderNum(item)"
                style="margin-left: 0px"
                plain
                >取消件数</el-button
              >
            </div>
            <div>款式名称：{{ item.goods_name }}</div>
            <div>
              已配件数：{{ item.distribution_quantity }}
              <span style="padding-left: 20px"
                >已配克重：{{ item.distribution_weight }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="distribution-footer">
      <el-button type="primary" style="width: 100%" @click="onSumbit">{{
        data.param.submit_status == 1 ? "取消提交" : "提交"
      }}</el-button>
      <el-button type="warning" style="width: 100%" @click="getPrintPda"
        >打印</el-button
      >
    </div>
  </div>
  <imgPlus></imgPlus>
  <el-dialog
    v-model="data.dialogVisible"
    title="配货"
    width="95%"
    :before-close="handleClose"
  >
    <div style="height: 85vh">
      <div
        style="display: flex; align-items: center; justify-content: flex-end"
      >
        <el-switch
          v-if="data.param.submit_status == 0"
          v-model="data.value4"
          inline-prompt
          active-text="配货"
          inactive-text="取消"
        />
      </div>
      <div
        class="distribution-item"
        v-for="(item, index) in data.itemList"
        :key="index"
      >
        <div
          class="distribution-item-title"
          @click="item.show = !item.show"
          @touchstart="touchStartEvent"
          @touchend="touchEndEvent($event, item)"
        >
          <span>条码：{{ item.barcode }}</span>
          <span>克重：{{ item.weight }}g</span>
          <el-button
            type="danger"
            style="position: relative"
            v-show="item.isDelete"
            @click.stop="deleteDistribution(item.barcode)"
            >删除</el-button
          >
        </div>
        <div class="distribution-item-body" v-show="item.show">
          <!-- <img style="width: 80px; height: 80px" :src="item.img" alt="" /> -->

          <el-image
            style="width: 80px; height: 80px"
            :src="item.img"
            :zoom-rate="1.2"
            :max-scale="7"
            :min-scale="0.2"
            :preview-src-list="[item.img]"
            :initial-index="4"
            fit="cover"
          />
          <div>
            <div>款号：{{ item.goods_code }}</div>
            <div>款式名称：{{ item.goods_name }}</div>
            <div>一级编码：</div>
            <div>二级编码：</div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
  <el-dialog
    v-model="data.dialogVisible2"
    title="删除配货"
    width="95%"
    :before-close="handleClose2"
  >
    <div>
      <el-checkbox
        v-model="data.checked1"
        label="同时删除此订单中的该产品"
        size="large"
      />
    </div>
    <template #footer>
      <el-button @click="handleClose2">取消</el-button>
      <el-button type="primary" @click="scanToCancel">确定</el-button>
    </template>
  </el-dialog>
  <el-dialog
    v-model="data.dialogVisible3"
    title="删除配货"
    width="95%"
    :before-close="handleClose3"
  >
    <div>
      取消件数:
      <el-input-number
        v-model="data.num"
        :min="0"
        :max="data.orderItem.undistribution_quantity"
      />
    </div>
    <template #footer>
      <el-button @click="handleClose3">取消</el-button>
      <el-button type="primary" @click="cancelItemNum">确定</el-button>
    </template>
  </el-dialog>
</template>
<script setup>
import { ref, reactive, onMounted, onBeforeUnmount, nextTick } from "vue";
// import { ElMessage } from "element-plus";
import {
  distribution_item_list,
  scan_to_distribution,
  scan_to_cancel,
  submit_distribution,
} from "@/api/ph";
import { counter_item_list, cancel_item_num } from "@/api/order";
import { useRoute, useRouter } from "vue-router";
import { print_pda } from "@/api/print";
import imgPlus from "@/components/imgPlus.vue";
import { ElMessageBox, ElMessage } from "element-plus";
import { h } from "vue";
const route = useRoute();
const router = useRouter();
const data = reactive({
  tableData: [],

  startX: 0, // 触摸开始的X坐标
  endX: 0, // 触摸结束的X坐标
  threshold: 150, // 滑动的阈值
  value3: true,
  value4: true,
  value5: true,
  value: "",
  item: {
    isDelete: false,
  },
  distribution_id: 0,
  param: {},
  param2: {},
  timer: null,
  tableData2: [],
  title: "配货单列表",
  dialogVisible: false,
  orderItem: {
    item_id: "",
  },
  itemList: [],
  cate_item_attr: [],
  level_3: "",
  dialogVisible2: false,
  checked1: false,
  num: 0,
  dialogVisible3: false,
  barcode2: "",
});
const touchStartEvent = (event) => {
  // data.item.isDelete = false;
  data.startX = event.touches[0].clientX;
};
const touchEndEvent = (event, item) => {
  // setTimeout(() => {
  //   data.item.isDelete = false;
  // },100)
  if (data.item.item_id != item.item_id) data.item.isDelete = false;
  data.item = item;
  data.endX = event.changedTouches[0].clientX;
  if (data.startX - data.endX > 10) item.isDelete = true;
  if (data.endX - data.startX > 10) item.isDelete = false;
};
// 开始扫描
const openScan = () => {
  if (typeof Android_Scan == "undefined" || Android_Scan == null) {
    ElMessage({
      message: "Android_Scan 对象为空！",
      type: "warning",
    });
    return;
  }
  var bRet = Android_Scan.js_open(); //在close前，只允许调用一次。
  if (bRet == true) {
    ElMessage({
      message: "开启成功！",
      type: "success",
    });
    var param = {
      mode: 4,
    };
    var strParam = JSON.stringify(param);
    Android_Scan.js_setScanLaserMode(strParam);
  } else {
    ElMessage({
      message: "开启失败！",
      type: "error",
    });
  }
};
const closeScan = () => {
  if (typeof Android_Scan == "undefined" || Android_Scan == null) {
    ElMessage({
      message: "Android_Scan 对象为空！",
      type: "warning",
    });
    return;
  }
  Android_Scan.js_close();
};
const skip = (path) => {
  router.go(-1);
};

const handleFunctionCall = () => {};
const deleteItem = () => {};
// 提交/取消配货单
const onSumbit = () => {
  if (data.timer != null) {
    clearTimeout(data.timer);
  }
  data.timer = setTimeout(async () => {
    const res = await submit_distribution({
      distribution_id: data.distribution_id,
      submit_status: data.param.submit_status == 1 ? 0 : 1,
    });
    if (res && res.status && res.data.code >= 1) {
      if (data.param.submit_status == 0) {
        getPrintPda();
      }
      getList();
    }
  }, 1000);
  // router.push("/login");
};
const onPrint = async () => {
  // console.info(data.param2.weight,'param')
  // alert(data.param2.weight)
  if (typeof Android_LcPrinter == "undefined" || Android_LcPrinter == null) {
    ElMessage({
      message: "Android_LcPrinter 对象为空！",
      type: "warning",
    });
    return;
  }
  const param = {
    text: `订单单据号:${data.param2.order_num}\r\n配货单据号:${
      data.param2.distribution_num
    }\r\n客号:${data.param2.cus_name || ""}\r\n店铺:${
      data.param2.cus_child_name || ""
    }\r\n配货件数:${data.param2.quantity}\r\n配货克重:${
      data.param2.weight
    }\r\n配货时间:${data.param2.create_time}\r\n业务员名称:${
      data.param2.wor_name || ""
    }\r\n客户备注:${data.param2.cus_memo}`,
  };
  const strParam = JSON.stringify(param);
  Android_LcPrinter.js_printText(strParam);
  // 多行换行
  const hh = {
    LINE_SPACING: "1-n",
  };
  const strHh = JSON.stringify(hh);
  Android_LcPrinter.js_setLineSpacing(strHh);
  Android_LcPrinter.js_start();
};
// 获取配货信息
const getList = async () => {
  const res = await distribution_item_list({
    distribution_id: data.distribution_id,
  });
  if (res && res.status && res.data.code >= 1) {
    res.data.data.data.item.map((item) => {
      item.isDelete = false;
      item.show = false;
    });
    data.tableData = res.data.data.data.item;
    data.param = res.data.data.data;
    if (data.tableData2.length <= 0) {
      getCounterItemList();
    }
    // console.info(res.data.data.data);
  }
};
// 获取订单明细配货信息
const getList2 = async () => {
  const res = await distribution_item_list({
    distribution_id: data.distribution_id,
    order_item_id: data.orderItem.item_id,
  });
  if (res && res.status && res.data.code >= 1) {
    data.itemList = res.data.data.data.item;
    // res.data.data.data.item.map((item) => {
    //   item.isDelete = false;
    //   item.show = false;
    // });
    // data.tableData = res.data.data.data.item;
    // data.param = res.data.data.data;
    // if (data.tableData2.length <= 0) {
    //   getCounterItemList();
    // }
    // console.info(res.data.data.data);
  }
};
// 测试方法
const changeEvent = () => {
  window.globalFunction(data.barcode);
};
// 添加配货
const addDistribution = async (barcode) => {
  const res = await scan_to_distribution({
    distribution_id: data.distribution_id,
    barcode,
    order_item_id: data.orderItem.item_id,
  });
  if (res && res.status && res.data.code >= 1) {
    if (data.dialogVisible) {
      getList2();
    } else {
      getList();
    }
    getCounterItemList();
    data.barcode2 = "";
  }
};
// 删除配货
const deleteDistribution = (barcode) => {
  data.barcode = barcode;
  data.dialogVisible2 = true;
};
const scanToCancel = async () => {
  const res = await scan_to_cancel({
    distribution_id: data.distribution_id,
    barcode: data.barcode,
    order_item_id: data.orderItem.item_id,
    cancel_order_item: data.checked1 ? 1 : 0,
  });
  if (res && res.status && res.data.code >= 1) {
     if (data.dialogVisible) {
      getList2();
     } else {
      getList();
     }
    data.barcode2 = "";
    getCounterItemList();
    data.checked1 = false;
    data.dialogVisible2 = false;
  }
};
// 打印接口
const getPrintPda = async () => {
  const res = await print_pda({ distribution_id: data.distribution_id });
  if (res && res.status == 200 && res.data.code >= 1) {
    data.param2 = res.data.data;
    onPrint();
  }
};
// 获取订单详情
const getCounterItemList = async () => {
  const res = await counter_item_list({
    order_id: data.param.yzt_order_id,
    goods_code: "",
    goods_name: "",
    weight: "",
    quantity: "",
    page: 1,
    page_size: 1000,
    is_undis: data.value5 ? 0 : 1,
    level_3: data.level_3,
  });
  if (res && res.status && res.data.code >= 1) {
    // res.data.data.data.item.map((item) => {
    //   item.isDelete = false;
    //   item.show = false;
    // });
    // data.tableData = res.data.data.data.item;
    // data.param = res.data.data.data;
    // console.info(res.data.data.data);
    // data.tableData2 = res.data.data.data;
    let datas =  res.data.data.data
    datas.map(item => {
      item.show = true
      data.tableData2.map(item2 =>{
        if(item2.item_id == item.item_id){
          item.show = item2.show
        }
      })
    })
    data.tableData2 = datas
    data.cate_item_attr = [
      { cate_id: "", cate_name: "全部" },
      ...res.data.data.cate_item_attr,
    ];
    // console.info(res.data.data.data);
  }
};
// 关闭弹框
const handleClose = () => {
  data.dialogVisible = false;
  data.orderItem.item_id = "";
  getList();
};
// 关闭弹框2
const handleClose2 = () => {
  data.dialogVisible2 = false;
  data.checked1 = false;
  // data.orderItem.item_id = "";
  // getList();
};
// 取消件数关闭弹框
const handleClose3 = () => {
  data.dialogVisible3 = false;
};
//订单列表配货
const orderItemDistribution = (item) => {
  data.orderItem = JSON.parse(JSON.stringify(item));
  data.dialogVisible = true;
  getList2();
};
// 订单列表取消件数
const cancelOrderNum = (item) => {
  if (item.undistribution_quantity <= 0) {
    ElMessage({
      message: "未配件数为0,不能取消件数！",
      type: "warning",
    });
    return;
  }
  data.orderItem = JSON.parse(JSON.stringify(item));
  // console.info(data.orderItem,'data.orderItem')
  data.dialogVisible3 = true;
};
const cancelItemNum = async () => {
  if (!data.num) return;
  const res = await cancel_item_num({
    quantity: data.num,
    item_id: data.orderItem.item_id,
  });
  if (res && res.status == 200 && res.data.code >= 1) {
    getCounterItemList();
    getList();
    data.dialogVisible3 = false;
  }
};
//输入条码触发接口
const updateBarcode = () => {
  if (data.param.submit_status == 0) {
    if (data.dialogVisible) {
      if (data.value4) {
        addDistribution(data.barcode2);
      } else {
        deleteDistribution(data.barcode2);
      }
    } else {
      if (data.value3) {
        addDistribution(data.barcode2);
      } else {
        deleteDistribution(data.barcode2);
      }
    }
  } else {
    ElMessage({
      message: "此配货单已提交",
      type: "warning",
    });
  }
};
onMounted(() => {
  data.distribution_id = route.params.distribution_id;
  // openScan();
  // 监听函数
  window.globalFunction = (data2) => {
    // console.log(data, "外部函数被调用了！");
    if (data.param.submit_status == 0) {
      if (data.dialogVisible) {
        if (data.value4) {
          addDistribution(data2);
        } else {
          deleteDistribution(data2);
        }
      } else {
        if (data.value3) {
          addDistribution(data2);
        } else {
          deleteDistribution(data2);
        }
      }
    } else {
      ElMessage({
        message: "此配货单已提交",
        type: "warning",
      });
    }

    // 你可以在这里做更多的事情
  };
  getList();
  if (typeof Android_LcPrinter == "undefined" || Android_LcPrinter == null) {
    ElMessage({
      message: "Android_LcPrinter 对象为空！",
      type: "warning",
    });
    return;
  }
  // 初始化打印机
  Android_LcPrinter.js_initPrinter();
  // 设置字体大小
  const size = {
    fontsize: 12,
  };
  const sizeParam = JSON.stringify(size);
  Android_LcPrinter.js_printFontSize(sizeParam);
  // 打印浓度
  const fontparam = {
    density: 30,
  };
  const mfontparam = JSON.stringify(fontparam);
  Android_LcPrinter.js_printConcentration(mfontparam);
});
onBeforeUnmount(() => {
  // closeScan();
});
</script>
<style>
.el-dialog {
  --el-dialog-margin-top: 1vh;
  --el-dialog-margin-bottom: 1vh;
}
.el-dialog__header {
  padding-bottom: 0px !important;
}
.distribution {
  width: 100vw;
  height: 100vh;
}
.distribution-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 5px 10px;
}
.distribution-title {
  margin: 0 auto;
  font-weight: 600;
  position: absolute;
  left: calc(50% - 32px);
  font-size: 16px;
}
.distribution-information {
  padding: 10px;
  padding-bottom: 5px;
  color: #333;
}
.distribution-information div {
  line-height: 20px;
  display: flex;
}
.distribution-information-title {
  width: 70px;
  text-align: justify;
  display: block;
  text-align: justify;
  text-align-last: justify;
}
.distribution-footer {
  position: fixed;
  bottom: 0px;
  padding: 10px;
  width: 100%;
  display: flex;
}
.distribution-body {
  padding: 5px 10px;
  height: calc(100vh - 210px);
  overflow-y: auto;
}
.distribution-item {
  background-color: #fff;
  padding: 7px 10px;
  box-shadow: 2px 2px 10px 2px #ebe9e9;
  border-radius: 5px;
  margin-bottom: 10px;
}
.distribution-item-title {
  display: flex;
  justify-content: space-between;
  line-height: 30px;
  font-weight: 600;
  position: relative;
}
.distribution-item-body {
  display: flex;
}
/* .distribution-item-body img {
  margin-right: 25px;
} */
</style>
