import axios from "axios";
import { ElMessageBox, ElMessage } from "element-plus";
// axios.create({
//   baseURL: "https://cloud.mfgold.com.cn",
//   timeout: 5000,
// });
axios.defaults.baseURL = '/admin';
axios.defaults.proxy = {
  host: 'https://cloud.mfgold.com.cn',
  port: 80,
  protocol: 'http'
};
// const http = 'http://yzt.cn'
// const http = "https://cloud.mfgold.com.cn";
const imghttp = "https://cloud.mfgold.com.cn/";
const http = "https://cloud.mfgold.com.cn/admin/";
// const http = "https://mfcloud.mfzb9999.com";
// const imghttp = "https://mfcloud.mfzb9999.com/";
export const request = (url, method, params) => {
  return new Promise((resolve, reject) => {
    axios({
      method: method,
      url: http + url,
      // url,
      data: params,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        ElMessage({
          message: "服务器异常！",
          type: "error",
        });
        // reject(err);
      });
  });
};
export const request2 = (url, method, params) => {
  return new Promise((resolve, reject) => {
    axios({
      method: method,
      url: http + url,
      // url,
      data: params,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        ElMessage({
          message: "服务器异常！",
          type: "error",
        });
        // reject(err);
      });
  });
};
//设置请求超时
//设置请求拦截  有时后台会根据客户端来返回数据设置H5是告诉后台是使用电脑浏览器H5打开的
axios.interceptors.request.use((config) => {
  // console.log(config, "config");
  const token = "Bearer " + sessionStorage.getItem("token");
  config.headers = {
    "Content-Type": "application/x-www-form-urlencoded;",
    // 'Content-Type':'multipart/form-data;boundary=----WebKitFormBoundaryk4ZvuPo6pkphe7Pl',
    Authorization: token,
    // Authorization:"Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJqd3Rfc2hvcCIsInN1YiI6Im1mX2Nsb3VkX3Rlc3QubWZ6Yjk5OTkuY29tIiwidXNlcl9pZCI6IjIiLCJpYXQiOjE3MTA4MTA1NDYsImp0aSI6Ijc4ZDMwMmRiODVkZmJkOWM0OTRiZTNmMWUwYmJiYWNjIn0.kluKa5JNDAA-mBcYiSFCnUEDxfHv36rtgzqH2Tsmd3g"
  };
  return config;
},(err) => {
  return Promise.resolve(err);
});

// HTTPresponse拦截
axios.interceptors.response.use(
  (res) => {
    const status = Number(res.status) || 200;
    const message = res.data.msg;
    const loginPath = sessionStorage.getItem("loginPath");
    // 后台定义 410 针对令牌过去的特殊响应码
    if (status === 410) {
      ElMessageBox.confirm("令牌状态已过期，请点击重新登录", "系统提示", {
        confirmButtonText: "重新登录",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          window.location.href = loginPath;
        })
        .catch(() => {});
      return;
    }
    // // 处理 500 网络异常
    if (status === 500) {
      ElMessage({
        message: "服务器异常！",
        type: "error",
      });
      // return Promise.reject(new Error(message));
    }

    if (status !== 200) {
      ElMessage({
        message: res,
        type: "error",
      });
      // return Promise.reject(new Error(message));
    } else {
      // router.push('/login')
      if (res.data.code <= 0) {
        ElMessage({
          message: message,
          type: "warning",
        });
      } else if (message == "获取成功" || message == "操作成功") {
      } else {
        ElMessage({
          message: message,
          type: "success",
        });
      }
    }

    return res;
  },
  (error) => {
    // 处理 503 网络异常
    if (error.response.status === 503) {
      ElMessage({
        message: error.response.data.msg,
        type: "error",
      });
    } else {
      ElMessage({
        message: "网络错误",
        type: "error",
      });
    }
    // NProgress.done();
    return Promise.reject(new Error(error));
  }
);
export default imghttp;
