import {request2,request} from './request';
const BASE = '/admin'
// 用户登录
export const login = (data) => {
    return request(`user/login`,"post",data)
}
// 获取用户所有节点
export const userNode = (data) => {
    return request(`user/node`,"post",data)
}
// 获取用户信息
export const userInfo = (data) => {
    return request(`user/info`,"post",data)
}