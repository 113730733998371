<template>
  <div class="login">
    <div class="login-header">登录页面</div>
    <div class="login-body">
      <el-input
        v-model="data.username"
        class="w-50 m-2"
        placeholder="请输入用户名"
        :prefix-icon="UserFilled"
      />
      <el-input
        style="margin-top: 10px"
        v-model="data.password"
        class="w-50 m-2"
        placeholder="请输入密码"
        :prefix-icon="Lock"
        :type="data.type"
      >
        <template #suffix>
          <el-icon class="el-input__icon" @click="changeType"
            ><Hide v-if="data.type == 'password'" /> <View v-else
          /></el-icon> </template
      ></el-input>
    </div>
    <el-button
      color="#1677ff"
      style="width: 100%; margin-top: 40px"
      @click="loginEvent"
      >登录</el-button
    >
  </div>
</template>
<script setup>
import { reactive } from "vue";
import {
  Search,
  User,
  Lock,
  UserFilled,
  View,
  Hide,
} from "@element-plus/icons-vue";
import { login,userNode,userInfo } from "@/api/login";
import { useRouter } from "vue-router";
const router = useRouter()
const data = reactive({
  username: "",
  password: "",
  type: "password",
});
const changeType = () => {
  if (data.type == "password") data.type = "";
  else data.type = "password";
};
const loginEvent = async () => {
  const res = await login({username:data.username,password:data.password});
  if (res && res.status == 200 && res.data.code >= 1) {
    getUserNode(res.data.data.info)
    getUserInfo(res.data.data.info.user_id)
    sessionStorage.setItem(
      "user",
      JSON.stringify({ username: data.username, ...res.data.data.info })
    );
    sessionStorage.setItem("loginPath", window.location.href);
    sessionStorage.setItem("token", res.data.data.token);
  }
};
const getUserNode = async (user) => {
  // const user = JSON.parse(sessionStorage.getItem("user"));
    const res = await userNode({ user_id: user.user_id });
    if (res && res.status == 200 && res.data.code >= 1) {
      sessionStorage.setItem("setUserNodeList",JSON.stringify(res.data.data))
      router.push("/index");
    }
};
const getUserInfo = async (user_id) => {
  const res = await userInfo({user_id})
  if (res && res.status == 200 && res.data.code >= 1) {
    console.info(res.data.data.role)
    if(res.data.data.role && res.data.data.role.length > 0){
      sessionStorage.setItem("roleName", res.data.data.name);
    }
    // sessionStorage.setItem("loginPath", window.location.href);
    // sessionStorage.setItem("token", res.data.data.token);
  }
}
</script>
<style scoped>
@import url("@/assets/css/input.css");
.login {
  /* width: calc(100vw - 40px); */
  height: 100vh;
  padding: 0px 20px;
  overflow: hidden;
}
.login-header {
  text-align: center;
  margin-top: 20vh;
  line-height: 32px;
  font-weight: 600;
  font-size: 30px;
  color: #1677ff;
  /* background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.063); */
}
.login-body {
  margin-top: 20px;
}

/* .login-body .el-input__wrapper {
    box-shadow: none !important;
    border-radius: 0px !important;
    border-bottom: 1px solid rgba(22, 119, 255, 0.3);
    background-color: transparent !important;
    height: 40px !important;
    line-height: 40px !important;
  }
  .login-body .el-input .el-input__icon{
    color: #1677ff !important;
  } */
</style>
