<template>
  <div>
    <div class="title">{{ data.roleName }}</div>
    <div class="button-group">
      <div class="button button1" @click="skip('/saveList')">留货</div>
      <div class="button button2" @click="skip('/orderList')">配货</div>
      <div class="button button3" @click="logout">退出</div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import {onMounted,reactive} from "vue";
const data = reactive({
  roleName:""
})
const router = useRouter()
const skip = (path) =>{
    router.push({ path});
}
const logout = () =>{
  sessionStorage.clear();
  router.push("/login");
}
onMounted(()=>{
  data.roleName = sessionStorage.getItem("roleName");
})
</script>

<style scope>
.title {
  color: #409eff;
  font-size: 22px;
  text-align: center;
  font-weight: 600;
  margin-top: 15vh;
}
.button-group {
  display: grid;
  grid-template-columns: 50vw 50vw;
  margin-top: 60px;
}
.button {
  width: 40vw;
  height: 100px;
  text-align: center;
  line-height: 100px;
  margin-left: 7vw;
  font-size: 20px;
  color: #fff;
  border-radius: 10px;
}
.button1 {
  background-color: #409eff;
}
.button2 {
  background-color: #E6A23C;
  margin-left: 4vw;
}
.button3 {
  background-color: #F56C6C;
  margin-top: 40px;
}
</style>
