<template>
  <div class="order">
    <div class="order-header">
      <span style="position: absolute; left: 10px; top: 10px" @click="skip2"
        ><el-icon><ArrowLeftBold /></el-icon
      ></span>
      <div class="order-title">订单列表</div>
    </div>
    <div class="order-information">
      <div style="display: flex;justify-content: space-between">
        <div class="order-information-item" style="width: 44.5vw;">
          <span class="order-information-title">订单号：</span>
          <el-input style="width:calc(44.5vw - 70px)" v-model="data.form.doc_num" placeholder=""></el-input>
        </div>
        <div class="order-information-item" style="width: 44.5vw;">
          <span class="order-information-title">业务员：</span>
          <el-input style="width:calc(44.5vw - 70px)" v-model="data.form.work_name" placeholder=""></el-input>
        </div>
      </div>
      <div class="order-information-item">
        <span class="order-information-title">条码：</span>
        <el-input v-model="data.form.barcode" placeholder=""></el-input>
      </div>
      <div class="order-information-item">
        <span class="order-information-title">客号：</span>
        <el-input v-model="data.form.cus_name" placeholder=""></el-input>
      </div>
      <div class="order-information-item">
        <span class="order-information-title">状态：</span>
        <el-select
          style="width: 190px; margin-right: 10px"
          v-model="data.form.status"
          placeholder=""
        >
          <el-option
            :label="item.status_str"
            :value="item.value"
            v-for="item in data.order_status"
          ></el-option>
        </el-select>
        <el-checkbox
          style="margin-right: 10px"
          label="超期"
          v-model="data.form.is_overdue"
        ></el-checkbox>
        <el-button type="primary" size="small" @click="onSearch"
          >搜索</el-button
        >
      </div>
    </div>
    <div class="order-body">
      <div
        class="order-item"
        v-for="(item, index) in data.tableData"
        :key="index"
        @click="skip(item.order_id)"
        style="position: relative;"
      >
        <div class="order-item-title">
          <div>订单号：{{ item.doc_num }}</div>
          <div>订单客号：{{ item.cus_name }}</div>
          <div>店铺：{{ item.cus_child_name }}</div>
          <div style="display: flex">
            <div style="width: 43vw">
              未配数量：{{ item.undistribution_quantity }}
            </div>
            <div>已配数量：{{ item.distribution_quantity }}</div>
          </div>
          <div style="display: flex">
            <div style="width: 43vw">
              未配克重：{{ item.undistribution_weight }}g
            </div>
            <div>已配克重：{{ item.distribution_weight }}g</div>
          </div>
          <div>订单日期：{{ item.create_time }}</div>
          <div>客户备注：{{ item.cus_memo }}</div>
          <div style="display: flex">
            <div style="width: 43vw;">业务员：{{ item.work_name }}</div>
            <el-button type="danger" @click.stop="cancelOrder(item)" v-if="item.status_str == '未配齐' || item.status_str == '待审核'  || item.status_str == '已配齐' ">取消订单</el-button>
          </div>
        </div>
        
        <div style="position: absolute;top: 5px;right: 5px;">
          <img src="../assets/images/dsh.png" v-if="item.status == 1 || item.status == -1" style="width: 30px;height: 30px;" alt="">
          <img src="../assets/images/wph.png" v-else-if="item.status == 2" style="width: 30px;height: 30px;" alt="">
          <img src="../assets/images/yph.png" v-else-if="item.status == 3" style="width: 30px;height: 30px;" alt="">
          <img src="../assets/images/yck.png" v-else-if="item.status == 4" style="width: 30px;height: 30px;" alt="">
          <img src="../assets/images/yqx.png" v-else-if="item.status == 6 || item.status == 7 || item.status == 8 ||item.status == 9" style="width: 30px;height: 30px;" alt="">
          <img src="../assets/images/ywc.png" v-else style="width: 30px;height: 30px;" alt="">
              <!-- <span v-if="item.status_str == '待审核'" class="yellow">{{
                item.status_str
              }}</span>
              <span v-else-if="item.status_str == '未配齐'" class="red">{{
                item.status_str
              }}</span>
              <span v-else-if="item.status_str == '已配齐'" class="blue">{{
                item.status_str
              }}</span>
              <span v-else-if="item.status_str == '已出库'" class="green">{{
                item.status_str
              }}</span>
              <span v-else-if="item.status_str == '已取消'" class="info">{{
                item.status_str
              }}</span>
              <span v-else>{{ item.status_str }}</span> -->
            </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { reactive, onMounted } from "vue";
import { order_list,counter_cancel } from "@/api/order";
import { useRouter } from "vue-router";
import { ElMessageBox } from "element-plus";
const router = useRouter();
const data = reactive({
  form: {
    cus_name: "",
    doc_num: "",
    status: "",
    is_overdue: false,
    work_name: "",
    barcode:''
  },
  tableData: [],
  page: 1,
  pageSize: 10,
  total: 0,
  order_status: [],
  orderItem:{}
});
const getOrderList = async () => {
  const res = await order_list({
    ...data.form,
    isoverdue: data.form.is_overdue == true ? 1 : 0,
    page: data.page,
    page_size: data.pageSize,
  });
  if (res && res.status == 200 && res.data.code >= 1) {
    data.tableData = [...data.tableData, ...res.data.data.data];
    data.order_status = res.data.data.order_status;
  }
};
const onSearch = () => {
  data.page = 1;
  data.tableData = [];
  getOrderList();
};
const skip = (id) => {
  router.push({ path: `/distribution/${id}` });
};
const skip2 = () => {
  router.push({ path: `/index` });
};
const cancelOrder = (row) => {
  // data.orderItem = item
  ElMessageBox.confirm(
    '确定删除此订单吗',
    // 'Warning',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      // type: 'warning',
      center: true,
    }
  )
    .then(async() => {
      const res = await counter_cancel({ order_id: row.order_id});
      if (res && res.status == 200 && res.data.code >= 1) {
        data.tableData.map((item,index) => {
          if(item.order_id == row.order_id){
            data.tableData.splice(index,1)
          }
        })
      }
    })
    .catch(() => {
    })
}

onMounted(() => {
  getOrderList();
  const divElement = document.querySelector(".order-body");

  divElement.onscroll = function (event) {
    // 在此处编写处理滚动事件的代码
    if (
      divElement.scrollHeight - divElement.scrollTop <=
      divElement.clientHeight + 30
    ) {
      data.page += 1;
      getOrderList();
      // 在这里执行到达底部后的操作
    }
  };
  // 监听函数
  window.globalFunction = (data2) => {
    // console.log(data, "外部函数被调用了！");
    data.form.barcode = data2

    // 你可以在这里做更多的事情
  };
});
</script>
<style scoped>
@import url("../assets/css/order.css");
.order {
  width: 100vw;
  height: 100vh;
}
.order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 5px 10px;
}
.order-title {
  margin: 0 auto;
  font-weight: 600;
  /* position: absolute;
  left: calc(50% - 32px); */
  font-size: 16px;
}
.order-information {
  padding: 10px;
  color: #333;
}
.order-information .order-information-item {
  line-height: 25px;
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: space-between;
}
.order-information-title {
  width: 70px;
  text-align: justify;
  display: block;
  text-align: justify;
  text-align-last: justify;
}
.order-footer {
  position: fixed;
  bottom: 10px;
  padding: 10px;
  width: 100%;
  display: flex;
}
.order-body {
  padding: 5px 10px;
  height: calc(100vh - 192px);
  overflow-y: auto;
}
.order-item {
  background-color: #fff;
  padding: 7px 10px;
  box-shadow: 2px 2px 10px 2px #ebe9e9;
  border-radius: 5px;
  margin-bottom: 10px;
}
.order-item-title {
  line-height: 20px;
  /* font-weight: 600; */
  position: relative;
}
.order-item-body {
  display: flex;
}
.order-item-body img {
  margin-right: 25px;
}
.red {
  color: #f56c6c;
}
.green {
  color: #13ce66;
}
.blue {
  color: #409eff;
}
.yellow {
  color: #e6a23c;
}
.dark {
  color: #909399;
}
</style>
