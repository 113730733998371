<template>
  <div class="order" @click="data.item.isDelete = false">
    <div class="order-header">
      <span style="position: absolute; left: 10px; top: 10px" @click="skip2"
        ><el-icon><ArrowLeftBold /></el-icon
      ></span>
      <span style="position: absolute; left: 28px; top: 8px">{{
        data.roleName
      }}</span>
      <div class="order-title">留货单</div>
    </div>
    <div class="order-information">
      <div class="order-information-item">
        <span class="order-information-title">客号：</span>
        <div 
        style="width: calc(45vw - 50px)"><cusCode @getCusId="getCusId"></cusCode></div>
      </div>
      <div class="order-information-item">
        <span class="order-information-title">状态：</span>
        <el-select
          style="width: calc(45vw - 50px)"
          v-model="data.param.status"
          size="small"
          placeholder=""
        >
          <el-option label="全部" value=""></el-option>
          <el-option label="未提交" :value="1"></el-option>
          <el-option label="已提交" :value="2"></el-option>
        </el-select>
      </div>
      <!-- <div class="order-information-item" @click="onSearch">
        <el-button type="primary" size="small">搜索</el-button>
      </div> -->
    </div>
    <div
      class="order-information"
      style="justify-content: space-between; padding-top: 0px"
    >
      <div class="order-information-item">
        <span class="order-information-title">条码：</span>
        <el-input v-model="data.barcode" style="width: calc(100vw - 150px)" />
      </div>
      <div class="order-information-item" @click="onSearch">
        <el-button type="primary" size="small">搜索</el-button>
      </div>
    </div>
    <div class="order-body">
      <div
        class="order-item"
        v-for="(item, index) in data.tableData"
        :key="index"
        @click="item.show = !item.show"
        @touchstart="touchStartEvent"
        @touchend="touchEndEvent($event, item)"
      >
        <div class="order-item-title" @click="skip(item.retention_id)">
          <div style="display: flex">
            <div style="width: 190px; margin-right: 10px">
              客号：{{ item.cus_shortname }}
            </div>
            <div>业务员：{{ item.wor_name }}</div>
          </div>
          <div style="display: flex; margin-top: 10px">
            <div style="width: 190px; margin-right: 10px">
              克重：{{ item.weight }}g
            </div>
            <div>件数：{{ item.quantity }}</div>
          </div>
          <div style="margin-top: 10px">
            <span style="padding-right: 20px"
              >时间：{{ item.create_time }}</span
            >
            <el-button type="warning" @click.stop="onPrint(item)" plain
              >打印标签</el-button
            >
          </div>
        </div>
        <el-button
          type="danger"
          style="height: 80px"
          v-show="item.isDelete"
          @click.stop="clearRetention(item.retention_id)"
          >删除</el-button
        >
      </div>
    </div>
    <el-button style="width: 100%" type="primary" @click="createRetentionEvent"
      >新建</el-button
    >
  </div>
  <el-dialog
    v-model="data.dialogVisible"
    title="取消留货"
    width="95%"
    :before-close="handleClose"
  >
    <div>
      <el-radio-group v-model="data.is_release">
        <el-radio :label="1" size="large">留货锁定</el-radio>
        <el-radio :label="2" size="large">留货上柜</el-radio>
      </el-radio-group>
    </div>
    <template #footer>
      <el-button @click="handleClose">取消</el-button>
      <el-button type="primary" @click="scanToCancel">确定</el-button>
    </template>
  </el-dialog>
</template>
<script setup>
import {
  retention_order_list,
  clear_retention,
  createRetention,
} from "@/api/retention";
import { reactive, onMounted } from "vue";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import cusCode from "@/components/cusCode.vue";
const router = useRouter();
const data = reactive({
  param: {
    cus_id: "",
    status: "",
  },
  tableData: [],
  startX: 0, // 触摸开始的X坐标
  endX: 0, // 触摸结束的X坐标
  threshold: 150, // 滑动的阈值
  item: {
    isDelete: false,
  },
  page: 1,
  pageSize: 10,
  total: 0,
  roleName: "",
  timer: null,
  value3: true,
  barcode: "",
  is_release: -1,
  barcode2: "",
  dialogVisible:false,
  retention_id:""
});
const getRetentionOrderList = async () => {
  const res = await retention_order_list({
    page: data.page,
    limit: data.pageSize,
    barcode: data.barcode,
    ...data.param,
  });
  if (res && res.status == 200 && res.data.code >= 1) {
    data.tableData = [...data.tableData, ...res.data.data.data];
    data.total = res.data.data.count;
  }
};

const touchStartEvent = (event) => {
  data.startX = event.touches[0].clientX;
};
const touchEndEvent = (event, item) => {
  if (data.item.item_id != item.item_id) data.item.isDelete = false;
  data.item = item;
  data.endX = event.changedTouches[0].clientX;
  if (data.startX - data.endX > 10) item.isDelete = true;
  if (data.endX - data.startX > 10) item.isDelete = false;
};
const onSearch = () => {
  data.page = 1;
  data.tableData = [];
  getRetentionOrderList();
};
const clearRetention = async (retention_id) => {
  data.retention_id = retention_id
  data.dialogVisible = true
};
const skip = (retention_id) => {
  router.push({ path: `/retention/${retention_id}` });
};
const skip2 = () => {
  router.push({ path: `/index` });
};
//创建留货单
const createRetentionEvent = async () => {
  if (data.timer != null) {
    clearTimeout(data.timer);
  }
  data.timer = setTimeout(async () => {
    const res = await createRetention();
    if (res && res.status == 200 && res.data.code >= 1) {
      router.push({ path: `/retention/${res.data.data}` });
    }
  }, 1000);
};
const getCusId = (cus_id) => {
  data.param.cus_id = cus_id;
};
const onPrint = async (data) => {
  if (typeof Android_LcPrinter == "undefined" || Android_LcPrinter == null) {
    ElMessage({
      message: "Android_LcPrinter 对象为空！",
      type: "warning",
    });
    return;
  }
  let black = true;
  var markparam = {
    MARK_ENABLE: black,
  };
  var mmarkparam = JSON.stringify(markparam);
  Android_Printer.js_printEnableMark(mmarkparam);
  const param = {
    text: `客号:${data.cus_shortname}\r\n业务员:${data.wor_name}\r\n重量:${
      data.weight || ""
    }\r\n件数:${data.quantity || ""}\r\n时间:${data.update_time}\r\n备注:${
      data.remarks
    }`,
  };
  const strParam = JSON.stringify(param);
  Android_LcPrinter.js_printText(strParam);
  // 多行换行
  const hh = {
    LINE_SPACING: "1-n",
  };
  const strHh = JSON.stringify(hh);
  Android_LcPrinter.js_setLineSpacing(strHh);
  Android_LcPrinter.js_start();
};
const handleClose = () => {
  data.dialogVisible = false
  data.is_release = -1
}
const scanToCancel = async () =>{
  if(data.is_release < 0){
      ElMessage({
        message: "请选择是留货锁定还是留货上柜",
        type: "warning",
      });
      return

  }
  const res = await clear_retention({ retention_id:data.retention_id, del_retention: 1,is_release:data.is_release });
  if (res && res.status == 200 && res.data.code >= 1) {
    data.tableData.map((item, index) => {
      if (data.retention_id == item.retention_id) {
        data.tableData.splice(index, 1);
      }
    });
    data.dialogVisible = false
    data.is_release = -1
  }
}
onMounted(() => {
  data.roleName = sessionStorage.getItem("roleName");
  getRetentionOrderList();
  const divElement = document.querySelector(".order-body");
  divElement.onscroll = function (event) {
    // 在此处编写处理滚动事件的代码
    if (
      divElement.scrollHeight - divElement.scrollTop <=
      divElement.clientHeight + 30
    ) {
      if (data.total != 0 && data.total / data.pageSize > data.page) {
        data.page += 1;
        getRetentionOrderList();
      }
      // 在这里执行到达底部后的操作
    }
  };
  if (typeof Android_LcPrinter == "undefined" || Android_LcPrinter == null) {
    ElMessage({
      message: "Android_LcPrinter 对象为空！",
      type: "warning",
    });
    return;
  }
  // 监听函数
  window.globalFunction = (data2) => {
    // console.log(data, "外部函数被调用了！");
    data.barcode = data2;
    // 你可以在这里做更多的事情
  };
  // 初始化打印机
  Android_LcPrinter.js_initPrinter();
  // 设置字体大小
  const size = {
    fontsize: 12,
  };
  const sizeParam = JSON.stringify(size);
  Android_LcPrinter.js_printFontSize(sizeParam);
  // 打印浓度
  const fontparam = {
    density: 30,
  };
  const mfontparam = JSON.stringify(fontparam);
  Android_LcPrinter.js_printConcentration(mfontparam);
});
</script>
<style scoped>
@import url("../assets/css/order.css");
.order {
  width: 100vw;
  height: 100vh;
}
.order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 5px 10px;
}
.order-title {
  margin: 0 auto;
  font-weight: 600;
  /* position: absolute;
  left: calc(50% - 32px); */
  font-size: 16px;
}
.order-information {
  padding: 10px;
  color: #333;
  display: flex;
}
.order-information .order-information-item {
  /* line-height: 25px; */
  display: flex;
  /* margin-top: 10px; */
  align-items: center;
  margin-top: 10px;
  margin-right: 10px;
  /* justify-content: space-between; */
}
.order-information-title {
  /* width: 50px;
  text-align: justify;
  display: block;
  text-align: justify;
  text-align-last: justify; */
}
/* .order-footer {
  position: fixed;
  bottom: 10px;
  padding: 10px;
  width: 100%;
  display: flex;
} */
.order-body {
  padding: 5px 10px;
  height: calc(100vh - 172px);
  overflow-y: auto;
}
.order-item {
  background-color: #fff;
  padding: 7px 10px;
  box-shadow: 2px 2px 10px 2px #ebe9e9;
  border-radius: 5px;
  margin-bottom: 10px;
  display: flex;
  align-content: center;
  justify-content: space-between;
}
.order-item-title {
  line-height: 20px;
  /* font-weight: 600; */
  position: relative;
}
.order-item-body {
  display: flex;
}
.order-item-body img {
  margin-right: 25px;
}
.red {
  color: #f56c6c;
}
.green {
  color: #13ce66;
}
.blue {
  color: #409eff;
}
.yellow {
  color: #e6a23c;
}
.dark {
  color: #909399;
}
</style>
