<template>
  <router-view/>
</template>

<style>
* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  font-size: 14px;
} 
body{
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  background: linear-gradient(
    81.85deg,
    rgba(254, 255, 255, 0.6) 18.45%,
    rgba(239, 245, 255, 0.6) 52.08%,
    rgba(248, 252, 255, 0.6) 87.89%
  );
  color: #606266;
}
</style>
