<template>
  <div class="order" @click="data.item.isDelete = false">
    <div class="order-header" style="position: relative">
      <span style="position: absolute; left: 10px; top: 10px" @click="skip"
        ><el-icon><ArrowLeftBold /></el-icon
      ></span>
      <span style="position: absolute; left: 28px; top: 8px">{{
        data.roleName
      }}</span>
      <div class="order-title">留货</div>
      <el-button
        type="danger"
        @click="createRetentionEvent"
        style="position: absolute; right: 10px; top: 2px"
        >下一单</el-button
      >
    </div>
    <div class="order-information">
      <div
        class="order-information-item"
        style="display: flex; justify-content: space-between"
      >
        <div style="display: flex; justify-content: space-between">
          <span class="order-information-title">时间：</span
          >{{ data.param.create_time }}
        </div>
        <el-switch
          v-model="data.value4"
          inline-prompt
          active-text="合并单"
          inactive-text="分开单"
        />

        <el-switch
          v-model="data.value3"
          inline-prompt
          active-text="留货"
          inactive-text="取消留货"
        />
      </div>
      <div style="display: flex">
        <div class="order-information-item" style="width: 40vw">
          <span class="order-information-title">重量：</span
          >{{ data.param.weight }}
        </div>
        <div class="order-information-item">
          <span class="order-information-title">件数：</span
          >{{ data.param.quantity }}
        </div>
      </div>
      <div class="order-information-item">
        <div
          class="order-information-item"
          style="width: 40vw; margin-top: 0px"
        >
          <span class="order-information-title" style="width: 60px"
            >业务员：</span
          >{{ data.param.wor_name }}
        </div>
        <div class="order-information-item" style="margin-top: 0px">
          <span class="order-information-title">条码：</span
          ><el-input
            placeholder="请输入条码"
            v-model="data.barcode"
            style="width: 120px"
            @blur="
              data.value3
                ? addRretention(data.barcode)
                : deleteRretention(data.barcode)
            "
          />
        </div>
      </div>
      <div class="order-information-item">
        <span class="order-information-title">客号：</span>
        <cusCode
          @getCusId="getCusId"
          :cus_shortname="data.cus_shortname"
          :cus_id="data.cus_id"
        ></cusCode>
      </div>
      <div class="order-information-item">
        <span class="order-information-title">备注：</span>
        <el-input
          placeholder="请输入备注"
          style="border: 1px solid #dcdfe6"
          v-model="data.param.remarks"
          @blur="retentionAddRemarks"
        />
      </div>
    </div>
    <div style="padding: 0px 10px">
      <el-button type="warning" style="width: 100%"
        >已留货({{ data.num }})</el-button
      >
    </div>
    <div class="order-body">
      <div
        class="order-item"
        v-for="(item, index) in data.param.items"
        :key="index"
        @click="item.show = !item.show"
        @touchstart="touchStartEvent"
        @touchend="touchEndEvent($event, item)"
      >
        <div class="order-item-title" style="width: 80%">
          <div
            style="
              display: flex;
              margin-top: 10px;
              justify-content: space-between;
            "
          >
            <div>条码：{{ item.barcode }}</div>
            <div>重量{{ item.weight }}g</div>
          </div>
        </div>
        <el-button
          type="danger"
          v-show="item.isDelete"
          @click.stop="deleteRretention(item.barcode)"
          >删除</el-button
        >
      </div>
    </div>
    <div
      v-if="data.param.status == 1"
      style="
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0px 10px;
      "
    >
      <el-button style="width: 45%" type="warning" @click="clearRetention"
        >一键清除</el-button
      >
      <el-button style="width: 45%" type="primary" @click="submitRetention"
        >提交</el-button
      >
    </div>
    <div
      v-else
      style="
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0px 10px;
      "
    >
      <el-button
        style="width: 100%"
        type="danger"
        @click="cancelSubmitRetention"
        >取消提交</el-button
      >
    </div>
    <div
      v-if="data.show"
      style="
        position: absolute;
        width: 100vw;
        height: 100vh;
        top: 0px;
        left: 0px;
        background-color: rgba(0, 0, 0, 0.4);
      "
    >
      <div
        style="
          width: 70vw;
          background-color: #fff;
          border-radius: 5px;
          margin-top: 30vh;
          margin-left: 15vw;
          padding: 30px 0px;
        "
      >
        <div style="padding: 10px; display: flex; align-items: center">
          <div style="width: 60px">客号：</div>
          <cusCode
            @getCusId="getCusId2"
            :cus_shortname="data.cus_shortname"
            :cus_id="data.cus_id"
          ></cusCode>
        </div>
        <div style="display: flex; justify-content: center; padding: 10px">
          <el-button
            type="danger"
            style="margin-right: 20px"
            @click="data.show = false"
            >取消</el-button
          >
          <el-button type="primary" @click="retentionEditCus">确定</el-button>
        </div>
      </div>
    </div>
  </div>
  <el-dialog
    v-model="data.dialogVisible"
    title="取消留货"
    width="95%"
    :before-close="handleClose"
  >
    <el-radio-group v-model="data.is_release">
      <el-radio :label="0">留货锁定</el-radio>
      <el-radio :label="1">留货上柜</el-radio>
      <!-- <el-radio :value="9">Option C</el-radio> -->
    </el-radio-group>
    <template #footer>
      <el-button @click="handleClose">取消</el-button>
      <el-button type="primary" @click="scanToCancel">确定</el-button>
    </template>
  </el-dialog>
</template>
<script setup>
import {
  retention_detail,
  submit_retention,
  cancel_submit_retention,
  retention_sku,
  clear_retention,
  release_sku,
  retention_add_cus,
  createRetention,
  retention_add_remarks,
} from "@/api/retention";
import { reactive, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import cusCode from "@/components/cusCode.vue";
const route = useRoute();
const router = useRouter();
const data = reactive({
  retention_id: "",
  param: {},
  cus_id: "",
  num: 0,
  startX: 0, // 触摸开始的X坐标
  endX: 0, // 触摸结束的X坐标
  threshold: 150, // 滑动的阈值
  item: {
    isDelete: false,
  },
  show: false,
  cus_shortname: "",
  roleName: "",
  timer: null,
  value3: true,
  value4: false,
  barcode: "",
  remarks: "",
  is_release: -1,
  barcode2: "",
  type: 0,
  dialogVisible: false,
});
const getRetentionDetail = async () => {
  const res = await retention_detail({ retention_id: data.retention_id });
  if (res && res.status == 200 && res.data.code >= 1) {
    data.param = res.data.data;
    data.num = data.param.items.length;
    data.cus_id = res.data.data.cus_id;
    data.cus_shortname = res.data.data.cus_shortname;
    if (res.data.data.is_split == 1) {
      data.value4 = false;
    } else {
      data.value4 = true;
    }
  }
};
const getRetentionDetail2 = async () => {
  const res = await retention_detail({ retention_id: data.retention_id });
  if (res && res.status == 200 && res.data.code >= 1) {
    data.param = res.data.data;
    data.num = data.param.items.length;
    data.cus_id = res.data.data.cus_id;
    data.cus_shortname = res.data.data.cus_shortname;
    if (res.data.data.is_split == 1) {
      data.value4 = false;
    } else {
      data.value4 = true;
    }
    onPrint();
  }
};
// 锁定库存
const addRretention = async (barcode) => {
  const res = await retention_sku({
    retention_id: data.retention_id,
    barcode,
  });
  if (res && res.status && res.data.code >= 1) {
    getRetentionDetail();
  }
};
// 释放库存
const deleteRretention = async (barcode) => {
  data.barcode2 = barcode;
  data.dialogVisible = true;
  data.type = 1;
};
const scanToCancel = async () => {
  if (data.is_release < 0) {
    ElMessage({
      message: "请选择是留货锁定还是留货上柜",
      type: "warning",
    });
    return;
  }
  if (data.type == 1) {
    const res = await release_sku({
      retention_id: data.retention_id,
      barcode: data.barcode2,
      is_release: data.is_release,
    });
    if (res && res.status && res.data.code >= 1) {
      data.param.items.map((item, index) => {
        data.param.items.splice(index, 1);
      });
      data.is_release = -1;
      data.dialogVisible = false;
    }
  } else if (data.type == 2) {
    const res = await clear_retention({
      retention_id: data.retention_id,
      del_retention: 0,
      is_release: data.is_release,
    });
    if (res && res.status == 200 && res.data.code >= 1) {
      data.is_release = -1;
      data.dialogVisible = false;
      getRetentionDetail();
    }
  }
};
// 一键清除
const clearRetention = async () => {
  data.dialogVisible = true;
  data.type = 2;
};
// 提交留货单
const submitRetention = async () => {
  if (data.timer != null) {
    clearTimeout(data.timer);
  }
  data.timer = setTimeout(async () => {
    if (!data.cus_id) {
      ElMessage({
        message: "请输入客号",
        type: "warning",
      });
      return;
    }
    let is_split = 0;
    if (data.value4 == false) {
      is_split = 1;
    }
    const res = await submit_retention({
      retention_id: data.retention_id,
      is_split,
    });
    if (res && res.status == 200 && res.data.code >= 1) {
      getRetentionDetail2();
    }
  }, 1000);
};
// 取消提交留货单
const cancelSubmitRetention = async () => {
  ElMessageBox.confirm(
    "确定取消此留货单吗",
    // 'Warning',
    {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      // type: 'warning',
      center: true,
    }
  )
    .then(() => {
      if (data.timer != null) {
        clearTimeout(data.timer);
      }
      data.timer = setTimeout(async () => {
        const res = await cancel_submit_retention({
          retention_id: data.retention_id,
        });
        if (res && res.status == 200 && res.data.code >= 1) {
          getRetentionDetail();
        }
      }, 1000);
    })
    .catch(() => {});
};
const skip = () => {
  router.push({ path: `/saveList` });
};
const touchStartEvent = (event) => {
  data.startX = event.touches[0].clientX;
};
const touchEndEvent = (event, item) => {
  if (data.item.item_id != item.item_id) data.item.isDelete = false;
  data.item = item;
  data.endX = event.changedTouches[0].clientX;
  if (data.startX - data.endX > 10) item.isDelete = true;
  if (data.endX - data.startX > 10) item.isDelete = false;
};
// 修改客号
const retentionEditCus = async () => {
  const res = await retention_add_cus({
    retention_id: data.retention_id,
    cus_id: data.cus_id,
  });
  if (res && res.status == 200 && res.data.code >= 1) {
    data.show = false;
    getRetentionDetail();
  } else {
    data.cus_id = "";
  }
};
const getCusId = (cus_id) => {
  data.cus_id = cus_id;
  retentionEditCus();
};
const getCusId2 = (cus_id) => {
  data.cus_id = cus_id;
};
const onPrint = async () => {
  if (typeof Android_LcPrinter == "undefined" || Android_LcPrinter == null) {
    ElMessage({
      message: "Android_LcPrinter 对象为空！",
      type: "warning",
    });
    return;
  }
  let black = true;
  var markparam = {
    MARK_ENABLE: black,
  };
  var mmarkparam = JSON.stringify(markparam);
  Android_Printer.js_printEnableMark(mmarkparam);
  const param = {
    text: `客号:${data.param.cus_shortname}\r\n业务员:${
      data.param.wor_name
    }\r\n重量:${data.param.weight || ""}\r\n件数:${
      data.param.quantity || ""
    }\r\n时间:${data.param.update_time}\r\n备注:${data.param.remarks}`,
  };
  const strParam = JSON.stringify(param);
  Android_LcPrinter.js_printText(strParam);
  // 多行换行
  const hh = {
    LINE_SPACING: "1-n",
  };
  const strHh = JSON.stringify(hh);
  Android_LcPrinter.js_setLineSpacing(strHh);
  Android_LcPrinter.js_start();
};
// 创建留货单
const createRetentionEvent = () => {
  if (data.timer != null) {
    clearTimeout(data.timer);
  }
  data.timer = setTimeout(async () => {
    const res = await createRetention();
    if (res && res.status == 200 && res.data.code >= 1) {
      data.cus_id = "";
      data.cus_shortname = "";
      router.push({ path: `/retention/${res.data.data}` });
      data.retention_id = res.data.data;
      getRetentionDetail();
      setTimeout(() => {
        if (!data.cus_id) {
          data.show = true;
        }
      }, 1000);
    }
  }, 1000);
};
// 提交备注
const retentionAddRemarks = async () => {
  const res = await retention_add_remarks({
    retention_id: data.retention_id,
    remarks: data.param.remarks,
  });
};
// 取消留货
const handleClose = () => {
  data.dialogVisible = false;
  data.is_release = -1;
};
onMounted(() => {
  data.retention_id = route.params.retention_id;
  data.roleName = sessionStorage.getItem("roleName");
  getRetentionDetail();
  setTimeout(() => {
    if (!data.cus_id) {
      data.show = true;
    }
  }, 1000);
  // 监听函数
  window.globalFunction = (data2) => {
    // console.log(data, "外部函数被调用了！");
    if (data.value3) {
      addRretention(data2);
    } else {
      deleteRretention(data2);
    }
    // 你可以在这里做更多的事情
  };

  if (typeof Android_LcPrinter == "undefined" || Android_LcPrinter == null) {
    ElMessage({
      message: "Android_LcPrinter 对象为空！",
      type: "warning",
    });
    return;
  }
  // 初始化打印机
  Android_LcPrinter.js_initPrinter();
  // 设置字体大小
  const size = {
    fontsize: 12,
  };
  const sizeParam = JSON.stringify(size);
  Android_LcPrinter.js_printFontSize(sizeParam);
  // 打印浓度
  const fontparam = {
    density: 30,
  };
  const mfontparam = JSON.stringify(fontparam);
  Android_LcPrinter.js_printConcentration(mfontparam);
});
</script>
<style scoped>
@import url("../assets/css/order.css");
.order {
  width: 100vw;
  height: 100vh;
}
.order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 5px 10px;
}
.order-title {
  margin: 0 auto;
  font-weight: 600;
  /* position: absolute;
  left: calc(50% - 32px); */
  font-size: 16px;
}
.order-information {
  padding: 10px;
  color: #333;
  /* display: flex; */
}
.order-information .order-information-item {
  /* line-height: 25px; */
  display: flex;
  /* margin-top: 10px; */
  align-items: center;
  margin-top: 10px;
  margin-right: 10px;
  /* justify-content: space-between; */
}
.order-information-title {
  width: 50px;
  text-align: justify;
  display: block;
  text-align: justify;
  text-align-last: justify;
}
/* .order-footer {
  position: fixed;
  bottom: 10px;
  padding: 10px;
  width: 100%;
  display: flex;
} */
.order-body {
  padding: 5px 10px;
  height: calc(100vh - 300px);
  overflow-y: auto;
}
.order-item {
  background-color: #fff;
  padding: 7px 10px;
  box-shadow: 2px 2px 10px 2px #ebe9e9;
  border-radius: 5px;
  margin-bottom: 10px;
  display: flex;
  align-content: center;
  justify-content: space-between;
}
.order-item-title {
  line-height: 20px;
  /* font-weight: 600; */
  position: relative;
}
.order-item-body {
  display: flex;
}
.order-item-body img {
  margin-right: 25px;
}
</style>
