import {request} from './request';
const BASE = '/admin'
// 留货单列表
export const retention_order_list = (data) => {
    return request(`retention/retention_order_list`,"post",data)
}
// 新建留货单
export const createRetention = (data) => {
    return request(`retention/create`,"post",data)
}
// 留货单添加/修改客号
export const retention_add_cus = (data) => {
    return request(`retention/retention_add_cus`,"post",data)
}
// 锁定库存
export const retention_sku = (data) => {
    return request(`retention/retention_sku`,"post",data)
}
// 释放库存
export const release_sku = (data) => {
    return request(`retention/release_sku`,"post",data)
}
// 提交留货单
export const submit_retention = (data) => {
    return request(`retention/submit_retention`,"post",data)
}
// 取消提交留货单
export const cancel_submit_retention = (data) => {
    return request(`retention/cancel_submit_retention`,"post",data)
}
// 一键清除明细 & 删除留货单
export const clear_retention = (data) => {
    return request(`retention/clear_retention`,"post",data)
}
// 留货单详情
export const retention_detail = (data) => {
    return request(`retention/retention_detail`,"post",data)
}
// 获取客号列表
export const cus_list = (data) => {
    return request(`retention/cus_list`,"post",data)
}
// 提交备注
export const retention_add_remarks = (data) => {
    return request(`retention/retention_add_remarks`,"post",data)
}