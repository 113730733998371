<template>
  <el-select
    v-model="data.cus_id"
    placeholder=""
    size="small"
    filterable
    @visible-change="visibleChange"
    style="width: 100%;"
    :filter-method="filterValue"
    popper-class="myselect"
    v-loadMore="handleScroll"
    @change="change"
    clearable
  >
    <el-option
      :label="item.cus_shortname"
      :value="item.cus_id"
      v-for="item in data.list"
      :key="item.cus_id"
    ></el-option>
  </el-select>
</template>
<script setup>
import { reactive, onMounted, defineEmits, defineProps, watch } from "vue";
import { cus_list } from "@/api/retention";
const data = reactive({
  item: {},
  value: "",
  cus_id: "",
  list: [],
  page: 1,
  limit: 10,
  loadingMore: false,
  show:false
});
const emit = defineEmits(["getCusId"]);
const prop = defineProps(["cus_shortname",'cus_id']);
// 自定义指令-下拉框触底加载
const vLoadMore = {
  mounted(el, binding) {
    let dom = document.querySelector(
      ".myselect .el-select-dropdown__wrap.el-scrollbar__wrap.el-scrollbar__wrap--hidden-default"
    );
    // 监听下拉框滚动事件
    dom.addEventListener("scroll", (e) => {
      if (
        e.target.scrollTop + e.target.clientHeight >=
        e.target.scrollHeight - 1
      ) {
        data.page += 1;
        getCusList(); // 触底加载更多数据
      } else {
      }
    });
  },
};
const visibleChange = (visible) => {
  if (visible) {
    // data.page = 1;
    // data.list = [];
    // data.value = "";
    // getCusList();
  }
};
const getCusList = async () => {
  const res = await cus_list({
    page: data.page,
    limit: data.limit,
    cus_name: data.value,
  });
  if (res && res.status == 200 && res.data.code >= 1) {
    data.list = [...data.list, ...res.data.data];
    data.loadingMore = false;
    
    // if ((data.show == true)) {
    //   data.show = false;
    //   if (res.data.data && res.data.data.length > 0)
    //     data.cus_id = res.data.data[0].cus_id;
    // }
  }
};
const filterValue = (query) => {
  data.value = query;
  data.list = [];
  getCusList();
};
const handleScroll = () => {
  console.log("触底了");
};
const change = () => {
  emit("getCusId", data.cus_id);
};
onMounted(() => {
  getCusList();
});
watch(
  () => prop.cus_shortname,
  (val) => {
      data.value = "";
    if (val && val != null) {
      data.value = val;
      data.show = true
      getCusList();
    }
  },
  {
    deep: true,
  }
);
watch(
  () => prop.cus_id,
  (val) => {
      data.value = "";
    if (val && val != null) {
      data.cus_id = val;
      // data.show = true
    }
  },
  {
    deep: true,
  }
);
</script>
<style scoped>
@import url("../assets/css/order.css");
</style>
